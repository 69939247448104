@font-face {
  font-family: 'CustomFont';
  src: local('OCR A Extended'),
    url(https://fonts.cdnfonts.com/s/14159/OCRAEXT_2.woff) format('woff')
}

.setting-card {
  width: fit-content;
  border-radius: 10px;
  justify-content: center;
  padding: 1.5rem 2.5rem;
  height: calc(100vh - 125px);

  .list-wrapper {
    gap: 1rem;
    display: flex;
  //  justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
    overflow-y: auto;
    max-height: 100%;
  .cardimage1{
    background-image: url("https://bnkd--dev.s3.ca-central-1.amazonaws.com/images/1689595732725_image-1689595731934.png");
  }
  .cardimage2{
    background-image: url("https://bnkd--dev.s3.ca-central-1.amazonaws.com/images/1689595775465_image-1689595774672.png");
  }
  .cardimage3{
    background-image: url("https://bnkd--dev.s3.ca-central-1.amazonaws.com/images/1689595595760_image-1689595595137.png");
  }
    .card-detail {
     // min-width: 310px;
     // max-width: 500px;
     width:400px;
      height: 240px;
   //   background-color: #005586;
      border: 1px solid rgba(0, 85, 134, 0.5);
      border-radius: 21px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
          .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        column-gap: 0.5rem;

        img {
          cursor: pointer;
        }
      }

      .chip {
        margin: 1.25rem 0 0.75rem 0;
      }

      .card-number {
        text-align: center;
        text-align: center;
        margin: .5rem 0;
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 1.4rem;
          font-family: CustomFont;
          font-weight: 600;
          color: #ffffff;
          letter-spacing: 1.5px;
        }
      }

      .card-user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;
      }

      .user-detail {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 1rem;

        div {
          color: #ffffff;
          text-align: center;
          font-size: 1rem;
          margin-top: -0.5rem;
        }
      }
    }
  }

  .add-new-carddetails {
  //  min-width: 310px;
   // max-width: 500px;
   width:400px;
    height: 240px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 85, 134, 0.5);
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10%;
    justify-self: flex-start;

    button {
      width: 40px;
      height: 40px;
      background: #005586;
      border-radius: 8px;
      border: none;
      color: #ffffff;
      font-weight: 500;
      font-size: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #005586;
    }
  }

  .not-default {
    width: 26px;
    height: 26px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
  }
}

@media screen and (max-width: 1500px) {
  .setting-card {
    .card-number {
      font-size: 1.3rem !important;
    }
  }
}

@media screen and (max-width: 1100px) {
  .setting-card {
    .add-new-card {
      width: 100%;
    }

    .card-detail {
      width: 100%;
    }
  }

  .ant-tabs-tabpane {
    padding-left: 16px !important;
  }
}
@media screen and (max-width:1400px)
{
  .add-new-carddetails {
    width: 375px !important;
  }
  
      .card-detail {
        width: 375px !important;
      }
}
@media screen and (max-width:900px)
{
  .setting-card {
    padding:0px 0px 0px 1rem !important;
  .add-new-card {
        width: 330px !important;
      }
  
      .card-detail {
        width: 330px !important;
      }
    }
}
@media screen and (max-width:800px)
{
  .setting-card {
    padding:0px 0px 0px 1rem !important;
  .add-new-card {
        width: 280px !important;
      }
  
      .card-detail {
        width: 280px !important;
      }
    }
}
@media screen and (min-width: 1101px) and (max-width: 1600px) {
  // .setting-card {
  //   .add-new-card {
  //     width: 100%;
  //   }

  //   .card-detail {
  //     width: 48%;
  //   }
  // }
}

@media screen and (min-width: 1601px) and (max-width: 2000px) {
  .setting-card {
    .add-new-card {
      width: 32%;
    }

    .card-detail {
      width: 32%;
    }
  }
}

@media screen and (min-width: 2001px) {
  .setting-card {
    .add-new-card {
      width: 24%;
    }

    .card-detail {
      width: 24%;
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;