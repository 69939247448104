.subscription-detail-modal {
  width: 520px;
  // left: calc(50% - 520px);
  // top: 50%;
  // transform: translate(-50%, -50%);

  .subscription-modal {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .headings {
      font-weight: 600;
      font-size: 28px;
      color: #003B5B;
    }

    .subscription-popup-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      gap: 10px;
      width: 100%;
      min-height: 184px;
      max-height: 237px;
      background: rgba(0, 85, 134, 0.1);
      border-radius: 15px;

      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        row-gap: 2rem;

        .total-amount,
        .pay-tax,
        .pay-amount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.5rem;
        }

        .total-amount,
        .pay-tax {
          font-weight: 400;
          font-size: 18px;
          color: #6B6B6B;
          width: 100%;
          text-align: left;
        }

        .pay-amount {
          font-weight: 700;
          font-size: 18px;
          color: #005586;
          width: 100%;
          text-align: right;
        }

      }

    }

  }

  .input-field {
    border: 0.2px solid rgba(0, 85, 134, 0.5);
  }

  .coupondisable {
    opacity: 0.2 !important;
  }

  .couponenable {
    opacity: 1 !important;
  }

  .success-modal {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .bnkd-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .modal-card-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .TextLevelSmallLabel {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      color: #6B6B6B;
    }

    .done-btn {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: #FFFFFF;
      text-align: center;
      width: 315px;
      height: 60px;
      padding: 16px;
      background: #003B5B;
      box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .bnkd-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-card-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #BEC4C6;

    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
        color: #003B5B;
      }
    }
  }

  .TextLevelSmallLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: #6B6B6B;
  }

  .add-new-card {
    .headings {
      font-weight: 600;
      font-size: 28px;
      color: #003B5B;
      text-align: center;
      padding-top: 1.5rem;
    }
  }

  .pay-now {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    height: 60px;
    padding: 16px;
    background: #003B5B;
    box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
    border-radius: 10px;
    cursor: pointer;
  }

  .pay-now-disabled {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    height: 60px;
    padding: 16px;
    background: #003B5B;
    box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
    border-radius: 10px;
    cursor: pointer;
    opacity: 0.2;
  }

  .card-element-wrapper {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 1.5rem;

    .pay-amount {
      width: 100%;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      background: rgba(0, 85, 134, 0.1);
      border-radius: 15px;

      #text {
        font-weight: 700;
        font-size: 18px;
        color: #005586;
      }

      #amount {
        font-weight: 700;
        font-size: 24px;
        color: #005586;
      }
    }

    .input-text-field {
      width: 100%;
      height: 60px;
      border-radius: 10px;
      font-weight: 500;
      font-size: 16px;
      color: #111111;
      background: #F9FCFD;
      padding: 0 1.25rem;
      border: 1.5px solid #005586;
    }

    .card-number {
      width: 100%;
      height: 60px;

      .InputContainer input {
        font-weight: 500;
        font-size: 16px !important;
        color: #111111;
      }
    }

    .card-number,
    .card-expiry,
    .card-cvv {
      border: 1.5px solid #005586;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 19px 16px;
    }

    .card-number,
    .card-expiry,
    .card-cvv {
      border: 1.5px solid #005586;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 19px 16px;
    }

    .CardBrandIcon-container {
      right: 0;
    }

    .label {
      font-family: 'Poppins';
      color: #111111;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 0.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .subscription-detail-modal {
    top: 50px;

    .card-cvv-wrapper {
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .subscription-detail-modal {
    top: 75px;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;