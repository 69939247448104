.membership-filters {
  position: absolute;
  width: 300px;
  height: 220px;
  right: 102px;
  top: 80px;
  z-index: 100;
  background: #FFFFFF;
  box-shadow: 0px 4px 34px rgba(0, 59, 91, 0.1);
  border-radius: 20px;

  .filters-content {
    width: 100%;
    padding: 0 1rem;
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: -13%;
  //   left: 53%;
  //   margin-left: -8px;
  //   border-width: 19px;
  //   border-style: solid;
  //   border-color: #FFFFFF; 
  //   border-left: 10px solid transparent;
  //   border-top: 10px solid transparent;
  //   border-right: 10px solid transparent;
  // }

  .radio-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    margin: 1rem 0;

    .divider {
      width: calc(100% - 6px);
      height: 0.5px;
      background: #003B5B;
      margin: 0.7rem auto;
    }

    .option {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 6px;
      border-radius: 8px;
      cursor: pointer;
      background: #FFFFFF;
      transition: all 0.3s ease;
    }

    .option .dot {
      height: 24px;
      width: 24px;
      border: 1px solid #BEC4C6;
      border-radius: 50%;
      position: relative;
      margin-right: 1rem;
    }

    input[type="radio"] {
      display: none;
    }

    #option-1:checked:checked~.option-1,
    #option-2:checked:checked~.option-2,
    #option-3.continue_btn:checked~.option-1,
    #option-4:checked:checked~.option-2 {
      border-color: #005586;
      background: #F9FCFD;
    }

    #option-1:checked:checked~.option-1 .dot,
    #option-2:checked:checked~.option-2 .dot,
    #option-3:checked:checked~.option-1 .dot,
    #option-4:checked:checked~.option-2 .dot {
      background: #003B5B;
    }

    #option-1:checked:checked~.option-1 .dot::before,
    #option-2:checked:checked~.option-2 .dot::before,
    #option-3:checked:checked~.option-1 .dot::before,
    #option-4:checked:checked~.option-2 .dot::before {
      position: absolute;
      left: 10%;
      top: 35%;
      height: 38%;
      width: 1px;
      background-color: #FFFFFF;
      content: "";
      transform: translateX(6px) rotate(-45deg);
      transform-origin: left bottom;
    }

    #option-1:checked:checked~.option-1 .dot::after,
    #option-2:checked:checked~.option-2 .dot::after,
    #option-3:checked:checked~.option-1 .dot::after,
    #option-4:checked:checked~.option-2 .dot::after {
      position: absolute;
      left: 15%;
      bottom: 0;
      top: 70%;
      height: 1px;
      width: 60%;
      background-color: #FFFFFF;
      content: "";
      transform: translateX(6px) rotate(-45deg);
      transform-origin: left bottom;
    }

    .option span {
      font-size: 16px;
      color: #111111;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 10px 0px;
      width: 125px;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      border-radius: 10px;
      border: none;
      outline: none;
    }

    .clear {
      background: #F1F1F1;
      color: #111111;
    }

    .apply {
      background: #003B5B;
      color: #FFFFFF;
    }

    .applydisabled {
      background: #003B5B;
      color: #FFFFFF;
      opacity: 0.2;
    }
  }
}

.membership-filters.table {
  top: 66px;
  right: 1rem;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;