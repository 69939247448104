.settings {
  height: 100%;

  .settingstab {
    margin-top: 20px;
    height: calc(100vh - 125px);
    box-shadow: 10px 0px 54px 0px #0000000D;
    width: 220px;

    form {
      margin-bottom: 1.25rem;
    }
  }

  .ant-tabs-nav-wrap {
    width: 100%;
  }

  .ant-tabs-nav-list {
    padding-top: .5rem;
    height: 100%;
    overflow-y: auto;

    .ant-tabs-ink-bar {
      background: transparent !important;
    }
  }

  .ant-tabs-tab {
    margin-top: 3% !important;
  }

  .ant-tabs-tab-active {
    background-color: #003B5B;
    border: 1px solid #003B5B;
    border-radius: 10px;
    margin: 3% auto 0 auto !important;
    width: calc(100% - 24px);
    padding: 8px 12px !important;
  }

  :where(.css-dev-only-do-not-override-ed5zg0).ant-tabs>.ant-tabs-nav,
  .ant-tabs-nav-list {
    width: 100%;
    gap: 3.5%;
    height: 100%;
    overflow-y: auto;
  }

  .settingcard {
    width: calc(84vw - 264px);
    height: calc(100vh - 125px);
    border: 1px solid #6B6B6B33;
    border-radius: 10px;
    box-shadow: 10px 0px 54px 0px #0000000D;
    overflow-y: auto;
  }

  .editsavebtn {
    position: fixed;
    bottom: 4vh;
  }

  .settingnames {
    font-size: 15px;
  }

  .imageicons {
    display: inline-block;
    width: 12px;
    height: 16px;
    margin-right: 14px;
  }

  ::placeholder {
    color: gray
  }

  @media(min-width:1999px) {
    .editsavebtn {
      bottom: 20vh;
    }
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray !important;
  opacity: 0.3;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray !important;
  opacity: 0.3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: gray !important;
  opacity: 0.3;
}

@media(max-width:1400px) {
  .settings {
    .settingnames {
      font-size: 14px;
    }
  }
}

@media(max-width:1300px) {
  .settingcard {
    width: calc(99vw - 444px) !important;
  }
}

.settings .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

#changepwdform {
  margin: 1.5rem;
  width: 60%;
  max-width: 500px;
  min-width: 325px;

  .headinginput {
    color: #6B6B6B;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }

  .save_btn {
    width: 100%;
    margin-bottom: 3%;
    background-color: #003B5B !important;
    color: #fff !important;
    height: 50px;
  }

  .save_btn_disabled {
    width: 100%;
    margin-bottom: 3%;
    background-color: #003B5B !important;
    opacity: 0.2;
    color: #fff !important;
    height: 50px;
  }
}

.helpcards {
  padding: 30px;
}

.contactuscard {
  padding: 30px;
  width: 75%;

  .contactheading {
    color: #111111;
    font-weight: 420;
    font-size: 18px;
    margin-bottom: 4%;
  }

  .emailbtn {
    display: flex;
    flex-direction: row;

    .emailinfo {
      color: #111111;
      font-weight: 600;
      font-size: 16px;
      margin-right: 45%;
    }

    .emailbtn {
      padding: 8px 30px;
      color: #fff;
      background-color: #003B5B;
      border-radius: 5px;
      text-align: center;
    }
  }

  .slaheading {
    color: #003B5B;
    font-size: 18px;
    font-weight: 600;
    margin: 2% 0;

  }

  .slacontent {
    color: #6B6B6B;
    line-height: 2rem;

    .contactinfo {
      color: #003B5B;
      font-weight: 500;
    }
  }

  .contactus {
    color: #003B5B;
    font-size: 18px;
    font-weight: 600;
    margin: 2% 0;
  }

}

.editprofilecard {
  padding: 1rem;

  .editheading {
    margin-bottom: 10px;
  }

  .input-field {
    color: #111111;
  }

  .input-fieldarea {
    color: #111111;
    width: 100%;
    height: 120px !important;
    background: #fff;
    padding-right: 2rem;
    overflow-y: scroll;

  }

  ::placeholder {
    color: #111111;
  }

  .addressdata {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    .addresshead2 {
      background-color: #003B5B;
      color: #fff;
      padding: 10px 16px;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  .file-upload-box {
    width: 109px;
    background-color: #DADFE4;
    border-radius: 10px;
    height: 109px;
    position: relative;
  }

  .file-upload-box1 {
    width: 200px;
    background-color: #DADFE4;
    border-radius: 50%;
    height: 200px;
    position: relative;
  }

  .save_btn {
    width: 500px;
    background-color: #003B5B !important;
    color: #fff !important;
    height: 50px;
  }

  .save_btnd {
    width: 500px;
    background-color: #003B5B !important;
    color: #fff !important;
    height: 50px;
    opacity: 0.5;
  }

  .camicon {
    position: absolute;
    top: 60%;
    left: 75%;
  }

  .profileplus {
    width: 200px;
    border-radius: 50%;
    height: 200px;
    position: relative;
    object-fit: cover;
  }
}

.subscriptions {
  padding: 5%;
  margin-left: 0;

  .activesub .ant-checkbox-checked .ant-checkbox-inner {
    background-color: green;
    border-color: green;
  }

  .inactivesub .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: red;
  }



  .status {
    margin-top: 2%
  }
}

.location-wrapper {
  width: 100%;
}

.addressmodal {
  .google-autosuggestion-list {
    margin-top: 0px;
    width: 100%;
    height: 239px;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    margin-bottom: 108px;
    position: absolute;
    z-index: 1;
    background: #fff;
    padding: 0 10px;
    overflow-y: auto;
  }
}

.editsaved {
  background-color: #003B5B;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: #003B5B;

}

.editsavedisabled {
  background-color: #003B5B !important;
  color: #fff !important;
  opacity: 0.7;
}

.addressmodal {
  width: 600px !important;
}

@media(max-width:1100px) {
  .settings {
    .emailbtn {
      flex-direction: column !important;
      margin: 1rem 0;
    }

    form {
      width: calc(100% - 1.5rem);
    }

    .save_btn {
      width: 28vh !important;
    }

    .save_btnd {
      width: 28vh !important;
    }

    .imagesettings {
      flex-wrap: wrap;
    }

    #changepwdform {
      width: 80%;
    }
  }
}

@media(min-width:1101px) {
  .settings {
    form {
      width: 500px;
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;