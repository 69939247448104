.payment-wrapper {
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/backgroundimg.png);
  .payment-detail {
    width: 100%;
    max-width: 900px;
    height: 100%;
    color: blue;
    display: flex;
    justify-content: center;
    align-items: center;

    .cards {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      position: relative;
      width: 85%;
      max-width: 1000px;
      height: fit-content;

      .ant-card {
        width: 100%;
        min-height: 600px;
        max-height: 92vh;
        height: 100%;
        background: #FFFFFF;
        border-radius: 20px;
        border: 1px solid rgba(107, 107, 107, 0.2);
        box-shadow: 0px 10px 90px rgba(0, 59, 91, 0.05);
      }

      .ant-card .ant-card-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    .bnkdlogo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3%;
    }

    .heading {
      display: flex;
      justify-content: center;
      font-family: Poppins;
      font-size: 28px;
      color: #003B5B;
      font-weight: 600;
      margin: 2% 0;
    }

    .center-container {
      width: 100%;
      display: flex;
      justify-content: center;

      .welcome-container {
        min-width: 300px;
      }
    }

    .setting-page {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 100%;

      .center-container {
        margin: 0 5% !important;
        justify-content: flex-start;
      }

      .ant-card {
        min-height: 700px;
        width: 100%;
        height: 100%;
      }

      .ant-card .ant-card-body {
        width: 100%;
        height: 100%;
        padding: 75px 0;
      }

      #name-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto;
        margin-top: 6%;
      }

      .ant-form-item {
        margin-bottom: 1.25rem !important;
      }

      button {
        margin-top: 0.75rem !important;
      }
    }

    .successmodal {
      display: none;
    }

    .cardinfo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #name-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: auto;
      margin-top: 6%;
    }

    ::placeholder {
      font-family: "Poppins";
    }

    .label {
      color: "#6B6B6B";
      font-weight: 500;
      font-size: 16px;
      margin-bottom: .75rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    label.upload-file {
      background: url('../../assets/images/cameraicon.svg');
      width: 55px;
      height: 46px;
      background-position: center;
      background-size: cover;

      input {
        display: none;
      }
    }

    .user-circle {
      background: #E7E8EA;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .user-rectangle {
      background: #E7E8EA;
      width: 50px;
      height: 50px;
      border-radius: 12%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .subform {
      display: flex;
      flex-direction: row !important;
    }

    .subform1 {
      flex: 1;

      input {
        width: 98% !important;
      }
    }

    .file-upload-box {
      width: 150px;
      background-color: #DADFE4;
      border-radius: 10px;
      height: 155px;
    }

    .done-btn {
      text-align: center;
      font-size: 20px;
      background: #003B5B;
      padding: 15px;
      border-radius: 10px;
      color: #fff;
      margin: auto;
      cursor: pointer;
    }

    .payment_continue_btn {
      width: 100%;
      height: 60px;

      background: #003B5B;
      box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
      border-radius: 10px;
      color: #FFFFFF;
    }

    .payment_continue_btn:hover {
      background: #003B5B;
    }

    .payment_continue_btn_disabled {
      width: 100%;
      height: 60px;
      background: #003B5B;
      opacity: 0.4;
      color: #FFFFFF;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .payment-detail {
    width: 90%;

    .ant-form {
      width: 90% !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .payment-detail {
    .heading {
      font-size: 3vw;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .payment-detail {
    width: 70%;

    .ant-form {
      width: 95% !important;
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;