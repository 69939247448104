.notificationcard {
  width: 45vw;
  min-height: 20vh;
  max-height: 88vh;
  z-index: 2;
  position: absolute;
  background-color: #FFFFFF;
  margin-top: 20px;
  left: 54vw;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);

  .closeimg {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 35px;
    height: 35px;
  }

  .heading {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .notificationlist {
    overflow-y: scroll;
    height: 71vh;

    .singlenot {
      display: flex;
      justify-content: space-between;
      background-color: #005586;
      color: #fff;
      margin-bottom: 10px;
      border-radius: 10px;

      .time {
        color: #FFFFFF;
        font-weight: 400;
        opacity: 0.5;
        font-size: 12px;
        margin: auto 20px;
        min-width:70px;
        max-width:140px;
      }

      .notificationblue {
        text-align: start;
        padding: 14px;
        border-radius: 15px;
        font-weight: 600;
      }
    }

    .singlenotifcation {
      display: flex;
      justify-content: space-between;
      background-color: #F4F7FA;
      color: #111111;
      margin-bottom: 10px;
      border-radius: 12px;

      .notification {
        text-align: start;
        padding: 14px;
        border-radius: 15px;
        font-weight: 600;

      }

      .time {
        color: #111111;
        font-weight: 400;
        opacity: 0.5;
        font-size: 12px;
        margin: auto 20px;
      min-width:70px;
      max-width:140px;
      }
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;