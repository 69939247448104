.signup-container {
  background: url(../../assets/images/backgroundimg.png);
  max-width: 100%;
  margin: auto;
  min-height: 100vh;
  .row {
    display: flex;
    flex-direction: row;
  }

  .signup-left-panel {
    .ant-carousel {
      .slick-dots li button {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #D1D5DB;
      }

      .slick-dots li.slick-active button {
        width: 30px;
        height: 8px;
        border-radius: 10px;
        background: #E2AE2C;
      }
    }
  }

  .signup-right-panel {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    order: 2;
    padding: 0 5%;

    .rightpanelcontent {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .bnkd_logo_signup{
      margin:auto;
      width:170px;
      height:40px;
      img{
        width:100%;
        height:100%;
      }
    }


  }

  #signup-form {
    .input-field {
      height: 50px;
      margin: 3px 0 3px 0;
      width: 100%;
      border:0.2px solid rgba(0, 85, 134, 0.5);
    }
  }

  .ant-modal-close {
    display: none !important;
  }


  .bnkd_logo_signup {
    margin-top: 2.5vh;
    text-align: center;
  }

  .signup-subheader {
    text-align: center;
    color: #003B5B;
    font-weight: 600;
    font-size: 48px;
    margin: 1rem 0;
  }

  .signup-para {
    font-size: 18px;
    color: #BEC4C6;
    text-align: -webkit-center;
  }

  .signup_continue_btn {
    width: 100%;
    // margin-bottom: 3%;
    background-color: #003B5B !important;
    color: #fff !important;
    height:55px;

  }

  .signup_continue_btn_disabled {
    width: 100%;
    //margin-bottom: 3%;
    background-color: #003B5B !important;
    opacity: 0.2;
    color: #fff !important;
    height:55px;
  }

  .rightpanelcontent {
    padding: 0 1rem;
  }

  .continuebtn {
    background-color: #003B5B !important;
    color: #fff !important;
    width: 100%;
    height: 60px !important;
    border-radius: 10px !important;
  }

  .continuebtn-disabled {
    background-color: #003B5B !important;
    color: #fff !important;
    width: 100%;
    height: 60px !important;
    border-radius: 10px !important;
    opacity: 0.2;
  }

  .account-confirmation-msg {
    text-align: center;
    color: '#6B6B6B';
    font-size: 16px;
    width: 100%;
    margin-top: 2%;
  }

  .login_click {
    font-weight: 500;
    cursor: pointer;
  }

  .splashimgstyle {
    height: 96vh;
    margin:2vh 0;
    width: 100%;
    // need to remove
   // object-fit: cover;
  }
}

.termsandcon {
  max-width: 100%;
  margin-top: 2%;
  min-height: 100vh;

  .ant-modal-content {
    background: url(../../assets/images/backgroundimg.png);
    background-color: #fff;
  }

  .ant-modal-close {
    display: none;
  }

  .agree_header {
    text-align: center;
    color: #003B5B;
    font-weight: 600
  }

  .agree_btn {
    text-align: 'center';
    font-size: '20px';
    background: '#003B5B';
    padding: '10px';
    border-radius: '10px';
    color: '#fff';
    margin: 'auto';
    cursor: 'pointer';
    width: 50%;
  }

  .agree-popup-content {
    margin: 10px;
    padding: 10px;
    height: 70vh;
    overflow-y: auto;
  }

  .modal-card-content {
    margin: 3px 15%;
  }
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #005586;
  border-color: transparent;
}
/*.....for large screens .....*/
@media(min-width:1600px) {
  .bnkd_logo_signup {
    margin-top: 5vh !important;
  }

  // .signup-data {
  //   margin: 4vh 0 !important;
  // }

  #signup-form {
    margin: 5vh 0;
  }


}
@media(max-height:800px)
{
  .bnkd_logo_signup {
    margin-top: 5vh !important;
  }
  .signup-data {
    margin:0vh 0;
  }
}
@media(max-height:700px)
{
  .bnkd_logo_signup {
    margin-top: 2vh !important;
  }
  .signup-data {
    margin:0vh 0;
  }
  .signup-container .signup-subheader {
    margin:0.5rem 0;
  }
  .signup-container .signup-subheader 
  {
    font-size:35px;
  }
  .signup-container #signup-form .input-field {
    height:40px;
  }
  .signup-container .signup-para {
    font-size: 14px !important;
  }
  .signup-container .account-confirmation-msg {
    margin-top: 1% !important;
  }
}

/*------  For Mobile Screen Styles  ----------*/
@media(max-width:1192px) {
  .signup-container {
    .signup-right-panel {
      margin-left: 0px;
    }

  }

}

@media(min-width:767px) and (max-width:992px) {
  .signup-container {
    .image-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      .splashimgstyle {
        margin: auto 0;
    height: 482px;
    width: 100%;
    object-fit: contain;
      }
    }
    


    .row {
      flex-direction: column-reverse;
    }
  }
}
@media(max-height:1025px) and (max-width: 825px) {
  .splashimgstyle {
    margin: auto 0;
height: 300px !important;
width: 100%;
object-fit: contain;
  }
 
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
  .signup-container {
    .bnkd_logo_signup {
      margin-top: 4%;
    }

    .agree_btn {
      width: 100%;
    }

    .agree_header {
      font-size: 20px;
    }

    .modal-card-content {
      margin: 0;
    }

    .row {
      flex-direction: column-reverse;
    }

    .splashimgstyle {
      margin: auto;
      height: 90vh;
    }
  }
}

@media(max-width:550px) {
  .signup-container {
    .bnkd_logo_signup img {
      width: 125px;
    }

    .signup-subheader {
      font-size: 20px;
    }

    .row {
      flex-direction: column-reverse;
    }

    .splashimgstyle {
      margin: auto;
      height: 60vh;
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;