.locationcard {
  width: 70%;
  margin: auto;
  height: 20px;

  .ant-card {
    .ant-card-body {
      width: 100%;
      height: 30px !important;
    }
  }
}

.addressdetails {
  font-size: 16px;
}

.sidebar {
  background: #003B5B;
  box-shadow: 0px 10px 60px rgba(15, 23, 42, 0.1);
  border-radius: 0 12px 12px 0;
  width: 15vw;
  height: 100vh;
  position: relative;

  .sidebar-toggler {
    background: #fff;
    width: 22px;
    border-radius: 50%;
    padding-left: 3px;
    position: absolute;
    top: 60px;
    right: -10px;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 10%);
    cursor: pointer;
    z-index: 99;
  }

  .sidebarmenu {
    background: none;
    margin-top: 15%;
    display: flex;
    flex-direction: column;

    .logout-btn {
      position: absolute;
      left: 0;
      bottom: 2%;
      right: 0;
    }

    .customclass {
      text-overflow: unset;
      display: flex;
      align-items: normal;
      height: fit-content;
      padding: 12px;
      margin: 5% auto;

      .ant-menu-title-content {
        display: flex;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
          overflow: visible;
        }
      }
    }

    .sidemenuitem {
      margin-left: 10px;
      font-weight: 400;
      font-size: 17px;
      white-space: initial;
      line-height: 1.4;
    }

    .sideweight {
      font-weight: 600;
    }

    .sideweightwhite {
      font-weight: 400;
    }
  }

  .ant-menu-item {
    color: #fff;
  }
}

.helpcard {
  .helpcardheading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3%;
    color: #111111;
    font-weight: 600;
    font-size: 18px;
  }

  .helpcontent {
    color: #6B6B6B;
    margin-bottom: 5%;
    font-size: 14px;
  }
}

.bnkdimg {
  margin-top: 15%;
  width: 60%;
  margin-left: 10%;
}

@media(max-width:1500px) {
  .sidebar {
    .sidemenuitem {
      font-size: 13px;
    }
  }

}

@media(max-width:1300px) {
  .sidebar {
    width: 180px;

    .sidemenuitem {
      font-size: 10px;
    }
  }
}

@media(max-width:800px) {
  .sidebar {
    // width: 162px;
  }

  .bnkdimg {
    margin-top: 30px;
    width: 80%;
  }
}

@media(max-width:500px) {
  .addressdetails {
    font-size: 10px !important;
  }

  .locationimg {
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark>.ant-menu .ant-menu-item-selected {
  color: #003B5B !important;
  font-weight: 600 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
  background-color: #fff !important;
}

.logoutmodal {
  .crossImage {
    margin-bottom: 1.5rem;

    img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      margin: auto;
    }
  }

  .logoutdesc {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    color: #111111;
    font-weight: 600;
    font-size: 20px;
    width: 270px;
    margin: auto;
    text-align-last: center;
  }

  .confirmButtons {
    justify-content: space-between;
    display: flex;
    height: 50px;
    margin-top: 10px;

    .yesbtn {
      width: 39%;
      height: 100%;
      background-color: #E7E8EA;
      padding: 8px 12px;
      border-radius: 15px;
      outline: none;
      border-color: #E7E8EA;
      font-size: 16px;
      font-weight: 400
    }

    .nobtn {
      width: 56%;
      height: 100%;
      margin-left: 5px;
      background-color: #003B5B;
      color: #fff;
      padding: 8px 15px;
      border-radius: 15px;
      font-size: 16px;
      font-weight: 450;
    }
  }

  .ant-modal-content {
    top: 30%;
    padding: 24px 24px;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;