.loading-state {
  top: 45%;
  background: transparent;

  .ant-modal-body {
    display: flex;
    justify-content: center;
  }

  .ant-modal-content, .ant-modal-body {
    background-color: transparent;
    box-shadow: none;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;