.delete-modal {
  position: absolute;
  width: 481px;
  height: fit-content;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 150px;
  padding-bottom: 0;

  .success-modal {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .bnkd-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .modal-card-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .TextLevelSmallLabel {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      color: #6B6B6B;
    }

    .buttons-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .yes-btn,
      .no-btn {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        width: 45%;
        max-width: 200px;
        height: 56px;
        padding: 16px;
        border-radius: 10px;
        cursor: pointer;
      }

      .yes-btn {
        color: #111111;
        background: #F1F1F1;
      }

      .no-btn {
        color: #FFFFFF;
        background: #003B5B;
        box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
      }
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;