.membership-modal {
  .searchicon {
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 1;
    margin-top: 13px;
    margin-left: 10px;
  }

  .searchicond {
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 1;
    margin-top: 13px;
    margin-left: 10px;
    color: gray !important;
    opacity: 0.1;

  }

  .input-price {
    padding-left: 1.5rem;
  }

  .membership-header {
    font-weight: 600;
    font-size: 30px;
    color: #003B5B;
  }

  .membership-heading {
    font-weight: 500;
    font-size: 16px;
    color: #111111;
  }

  .input-field {
    height: 45px;
    border: 1.5px solid rgba(0, 85, 134, 0.5);
  }

  .description-card {
    padding-right: 2.2rem;
    overflow: hidden;
    width: 100%;

    * {
      width: 100%;
    }
  }

  .radio-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin: 1rem 0;

    .option {
      height: 48%;
      width: 48%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 1rem;
      border-radius: 8px;
      cursor: pointer;
      background: #FFFFFF;
      border: 1.5px solid rgba(0, 85, 134, 0.5);
      transition: all 0.3s ease;
    }

    .option .dot {
      height: 24px;
      width: 24px;
      border: 1px solid #BEC4C6;
      border-radius: 50%;
      position: relative;
      margin-right: 1rem;
    }

    input[type="radio"] {
      display: none;
    }

    #option-1:checked:checked~.option-1,
    #option-2:checked:checked~.option-2,
    #option-3.continue_btn:checked~.option-1,
    #option-4:checked:checked~.option-2 {
      border-color: #005586;
      background: #F9FCFD;
    }

    #option-1:checked:checked~.option-1 .dot,
    #option-2:checked:checked~.option-2 .dot,
    #option-3:checked:checked~.option-1 .dot,
    #option-4:checked:checked~.option-2 .dot {
      background: #003B5B;
    }

    #option-1:checked:checked~.option-1 .dot::before,
    #option-2:checked:checked~.option-2 .dot::before,
    #option-3:checked:checked~.option-1 .dot::before,
    #option-4:checked:checked~.option-2 .dot::before {
      position: absolute;
      left: 10%;
      top: 35%;
      height: 38%;
      width: 1px;
      background-color: #FFFFFF;
      content: "";
      transform: translateX(6px) rotate(-45deg);
      transform-origin: left bottom;
    }


    #option-1:checked:checked~.option-1 .dot::after,
    #option-2:checked:checked~.option-2 .dot::after,
    #option-3:checked:checked~.option-1 .dot::after,
    #option-4:checked:checked~.option-2 .dot::after {
      position: absolute;
      left: 15%;
      bottom: 0;
      top: 70%;
      height: 1px;
      width: 60%;
      background-color: #FFFFFF;
      content: "";
      transform: translateX(6px) rotate(-45deg);
      transform-origin: left bottom;
    }

    .option span {
      font-size: 16px;
      color: #111111;
    }
  }

  .continue_btn_disabled {
    width: 100%;
    margin-bottom: 2%;
    background-color: #003B5B !important;
    opacity: 0.2;
    color: #fff !important;
  }

  .continue_btn {
    width: 100%;
    margin-bottom: 2%;
    background-color: #003B5B !important;
    color: #fff !important;
  }

  .disabled {
    opacity: 0.25;
    cursor: not-allowed !important;

    * {
      cursor: not-allowed !important;
      user-select: none;
      pointer-events: none;
    }
  }

  .ql-editor {
    height: 150px;
    // border-radius:10px;
  }

  .ant-modal-close {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #BEC4C6;
    top: 10px;
    inset-inline-end: 10px;

    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 14px;
        height: 20px;
        color: #003B5B;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .membership-modal {
    .ant-modal-close {
      top: 5px;
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;