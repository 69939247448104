.customercomp {
  padding: 0.75rem 0;

  hr {
    margin: 1rem 0;
  }

  .searchicon {
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 1;
    margin-top: 10px;
    margin-left: 10px;
  }

  .searchinputs {
   padding:0 0 0 1.2vw;
    .input-search {
      input {
        font-size: 16px;
        background-color: #FAFAFA;
        padding-left: 3rem;
      }
    }
  }

  ::placeholder {
    font-size: 14px;
    text-align: left;
    font-family: "poppins";
  }

  .searchmenu {
    margin-top: 3px;
    width: 40%;
    // min-height: 32px;
    // max-height: 239px;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    position: absolute;
    background: #fff;
    opacity: 1;
    padding: 0 10px;
    overflow-y: auto;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 12px 26px -4px rgba(0, 59, 91, 0.08);
    .searchitem{
      padding:4px 0;
    }
  }

  .menulenmore {
    min-height: 32px;
    max-height: 239px;
  }

  .menulenone {
    height: 40px;
  }

  .searchcustimg {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    border-radius: 10px;
  }

  .customerdetails {
    width: 100%;
    padding: 0 1rem;

    .profileinfo {
      width: 60%;
      height: 73vh;
      display: flex;
      flex-direction: column;

      .profileinfoone {
        width: 95%;
        justify-content: space-between;
      }

      .profileimg {
        height: 80px;
        width: 80px;
      }

      .profilenameandemail {
        text-align: justify;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 22px;
        margin-left: 10px;
      }

      .profileupdatebtn {
        height: 40px;
        background-color: #003B5B;
        color: #fff;
        width: 116px;
        margin-bottom: 10px;
        display: block;
        font-weight: 500;
        padding: 0px;
      }

      .profilebonusbtn {
        padding: 0px;
        height: 40px;
        background-color: #fff;
        color: #fff;
        width: 116px;
        display: block;
        color: #003B5B;
        font-weight: 600;
      }

      .profilebtns {
        flex-direction: column;
      }

      .profileinfotwo {
        .profileinfod1 {
          padding: 5px 10px 2px 5px;
          justify-content: space-between;
          width: 95%;
        }

        .profilebal {
          color: #6B6B6B;
          font-size: 16px;
        }

        .profileamount1 {
          color: #005586;
          font-weight: 600;
          font-size: 18px;
        }

        .profileamount2 {
          color: #E2AE2C;
          font-weight: 600;
          font-size: 18px;
        }

        .profileamount3 {
          color: #F70000;
          font-weight: 600;
          font-size: 18px;
        }

      }

      .businesssub {
        overflow-y: auto;
        overflow-x: hidden;
      }

      .subscribedpackage {
        padding: 5px 10px 2px 5px;


        // padding:
        .subscribedpac {
          color: #003B5B;
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .packageinfo {
          display: flex;
          justify-content: space-between;
          color: #6B6B6B;
          font-size: 15px;
          padding-top: 10px;
          width: 95%;
        }
      }

      .businnesnamestatus {
        display: flex;
        padding: 0 5px 10px 10px;
        justify-content: space-between;
        width: 98%;

        .businessname {
          color: #111111;
          font-weight: 600;
          font-size: 18px;
        }

        .businesstatusactive {
          background-color: rgba(39, 174, 96, 0.2);
          width: 95px;
          padding: 10px 0px 10px 10px;
          text-align: center;
          border-radius: 5px;
          color: #27AE60;
          position: relative;

          .cirlce1 {
            background-color: #27AE60;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-top: 6px;
            margin-left: -16px;
          }
        }
    .status {
      font-weight: 500;
      font-size: 16px;
      color: #DB524E;
      background: rgba(247, 0, 0, 0.2);
      border-radius: 5px;
      padding: 5px 15px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        height: 7px;
        width: 7px;
        background: #DB524E;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
    .active {
      color: #27AE60;
      background: rgba(39, 174, 96, 0.2);
  
      span {
        background: #27AE60;
      }
    }
  }
  .paymentbtns {
        display: flex;
        justify-content: space-evenly;
        position: fixed;
        bottom: 2vh;

        .pausemembership {
          width: 300px;
          color: #111111;
          font-size: 16px;
          padding: 9px;
          height: 45px;
          background-color: #F1F1F1;
          font-weight:500;

        }

        .paymentbtn {
          width: 300px;
          background-color: #003B5B;
          font-size: 16px;
          padding: 9px;
          height: 45px;
          color: #F1F1F1;
          margin-left: 10%;

        }
      }
    }

    .custtransactionsinfo {
      width: 40%;

      .ant-card {
        height: 76vh;
      }

      .transactioninfo {
        height: 68vh;
      }
    }

    .transactionsinfo {
      width: 40%;

      .ant-card {
        height: 84vh;
      }

      .transactioninfo {
        height: 75vh;
      }
    }

    .transactionsheader {
      font-size: 24px;
      font-weight: 600;
      color: #003B5B;
    }

    .headingamont {
      justify-content: space-between;

      .amountpaidred {
        color: #F70000;
        font-weight: 600;
      }

      .amountpaidblack {
        color: #111111;
        font-weight: 600;
      }
    }
    .headingamont1 {
      justify-content: end;

      .amountpaidred {
        color: #F70000;
        font-weight: 600;
      }

      .amountpaidblack {
        color: #111111;
        font-weight: 600;
      }
    }
  }
}

.paymentmodal {
  .ant-modal-content {
    padding:10px 20px 20px 32px;
  }

  .crossicon {
    margin-left: 96%;
    cursor: pointer;
  }
  .searchicon
 {
  position:absolute;
  z-index:1;
  margin-left:10px;
  margin-top:10px;
  
 }

  .makepayment {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #003B5B;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .makepaymentheading {
    margin-bottom: 10px;
  }

  .input-field {
    margin-bottom: 10px;
  }
  .input-field1{
    margin-bottom: 10px;
    padding-left:1.5rem;
  }

  .makepaymentbtn {
    padding: 10px;
    height: 50px;
    background-color: #003B5B;
    color: #fff;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
  }
  .makepaymentbtn:hover{
    color:#fff;
  }

  .makepaymentbtndisabled {
    padding: 10px;
    height: 50px;
    background-color: #003B5B;
    color: #fff;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
    opacity: 0.2;

  }
}

.addbonusmodal {
  .ant-modal-content{
    padding:10px 24px;
  }
  .crossicon {
    margin-left: 99%;
    cursor: pointer;
    position: relative;
    right: 20px;
  }

  .addbonusheader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #003B5B;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .addbonusinput {
    color: #6B6B6B;
    position:relative;
 
  }
  
 .searchicon
 {
  position:absolute;
  z-index:1;
  margin-left:10px;
  margin-top:13px;
  
 }
  .input-field {
    margin-bottom: 5%;
    padding-left:1.5rem;
    
  }
  input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
    }
    
    input[type=number] {
      -moz-appearance: textfield;
    }

  .addbonusbtn {
    padding: 10px;
    height: 50px;
    background-color: #003B5B;
    color: #fff;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;

  }
  .addbonusbtn:hover
  {
   color:#fff;
  }

  .addbonusbtndisabled {
    padding: 10px;
    height: 50px;
    background-color: #003B5B;
    color: #fff;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
    opacity: 0.2;

  }

}

.updatedetailsmodal {
  .ant-modal-content {
    padding: 10px 20px 20px 32px;
  }
  .crossicon {
    margin-left: 99%;
    cursor: pointer;
    position: relative;
    right: 20px;
  }
  .updateinfoheader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #003B5B;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .input-field {
    height: 45px;
  }

  .updateuserlogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;

    .userimg {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .updatebtn {
    padding: 10px;
    height: 50px;
    background-color: #003B5B;
    color: #fff;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
  }
  .updatebtn:hover{
    color:#fff;
  }

  .updatebalance {
    position: relative;
  }

  .updateheader {
    color: #6B6B6B;
  }

  .addbonus {
    position: absolute;
    background-color: #003B5B;
    color: #fff;
    height: 30px;
    left: 80%;
    margin-top: 5px;
    top: 37%;
    padding: 3px;
  }

}

@media screen and (max-width: 1250px) {
  .paymentbtns .pausemembership {
    left: 5%;
    width: 170px !important;
  }

  .paymentbtns .paymentbtn {
    left: 5%;
    width: 200px !important;
  }

  .customercomp .customerdetails .profileinfo .profileupdatebtn {
    width: 122px;
  }

  .customercomp .customerdetails .profileinfo .profilebonusbtn {
    width: 120px;
  }

}

.pausemembershipmodal {
  .pausememberheading {
    font-size: 24px;
    color: #111111;
    font-weight: 600;
    width: 400px;
    text-align: center;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 6%;
  }

  .crossicon {
    position: absolute;
    top: 0.2rem;
    right: 1.1rem;
    cursor: pointer;
    img{
      width:25px;
      height:25px;
      }
  }

  .membershipdetails {
    height: 150px;
    overflow-y: scroll;

    .membershipinfo {
      display: flex;
      justify-content: space-between;
      padding: 0 10%;

      .membershiptitle {
        font-size: 18px;
        color: #111111;
        font-weight: 400;
        margin-bottom: 20px;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        width: 18px !important;
        height: 18px !important;
        background-color: #003B5B;
        border-color: #003B5B;
      }
    }
  }

  .pauseMemBtn {
    display: flex;
    justify-content: center;
  }

  .pausedisabledbtn {
    width: 65%;
    height: 50px;
    max-width: 460px;
    min-width: 400px;
    margin-bottom: 3%;
    background-color: #003B5B !important;
    opacity: 0.2;
    color: #fff !important;
  }

  .pausebtn {
    width: 65%;
    height: 50px;
    max-width: 460px;
    min-width: 400px;
    margin-bottom: 3%;
    background-color: #003B5B !important;
    color: #fff !important;
  }

}

@media screen and (max-width: 1100px) {
  .customercomp {
    padding-left: 0px !important;
  }

  .profilename {
    font-size: 14px;
  }

  .profilemail {
    font-size: 14px !important;
  }

  .customercomp .customerdetails .transactionsinfo {
    width: 35%;
  }

  .transactioninfo {
    height: 76vh !important;
  }
}

@media(max-width:820px) {
  .customercomp .customerdetails .profileinfo .profilenameandemail {
    width: 90px;

    .profilemail {
      font-size: 11px !important;
    }
  }

  .customercomp .customerdetails .profileinfo .profileupdatebtn {
    width: 112px;
  }

  .customercomp .customerdetails .profileinfo .profileupdatebtn {
    padding: 4px;
  }

  .customercomp .customerdetails .profileinfo .profilebonusbtn {
    width: 110px;
    margin-left: 2px;
  }

  .customercomp .customerdetails .profileinfo .profilebonusbtn {
    padding: 4px;
  }

  .transactioninfo {
    height: 74vh !important;
  }
}

@media screen and (max-width: 800px) {
  .customercomp .customerdetails .profileinfo .profileupdatebtn {
    width: 88px;
    font-size: 10px;
  }

  .customercomp .customerdetails .profileinfo .profilebonusbtn {
    width: 88px;
    font-size: 10px;
  }

  .customercomp .customerdetails .profileinfo .profileinfoone {
    width: 99%;
  }

  .paymentbtns .paymentbtn {
    left: 0%;
  }

  .customercomp .customerdetails .transactionsheader {
    font-size: 14px;
  }

  .transactioninfo {
    height: 78vh !important;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;