.subscribed-customers {
  width: 85vw;

  .ant-dropdown-menu-item {
    height: 60px;
    width: 200px;
  }

  .table-wrapper {
    width: 100%;
    height: 100%;
    padding: 10px;

    .custom-tooltip::after {
      left: 89% !important
    }

    table {
      width: 100%;
      // height: 100%;
      border-collapse: collapse;

      .body-row {
        .name {
          width: 22%;
          cursor: pointer;
        }

        .email {
          width: 30%;

        }

        .package {
          width: 21%;
        }

        .since {
          width: 17%;
        }

        .status {
          width: 10%;

        }
      }

      .table-head .table-head1 {
        width: 280px;
      }

      th,
      td {
        padding-left: 0.75rem;
        font-size: 16px;
      }

      // td:nth-child(2) {
      //   width:300px;
      // }

      thead {
        height: 55px;
        background: #FAFAFA;
        border-left: 1px solid #E7EAEE;
        border-right: 1px solid #E7EAEE;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        th {
          font-weight: 600;
          color: #003B5B;

          span>svg {
            margin-bottom: 5px;
            cursor: pointer;
          }
        }

        th:last-child {
          cursor: pointer;
        }
      }

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      tbody {
        height: calc(100vh - 170px);
        overflow: auto;
        display: block;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-left: 1px solid #E7EAEE;
        border-right: 1px solid #E7EAEE;

        tr {
          height: 75px;
          border-bottom: 1px solid #E7EAEE;

          td {
            font-weight: 500;
            vertical-align: middle;

            img {
              width: 35px;
              height: 35px;
              margin-right: 0.5rem;
              border-radius: 50%;
            }
          }

          .package {
            color: #E2AE2C;
          }

          .since {
            color: #912665;
          }

          .status {
            border-radius: 5px;
            padding: 5px 10px;
            height: fit-content;
            width: fit-content;
            text-transform: capitalize;
          }

          .status.active {
            color: #27AE60;
            background: rgba(39, 174, 96, 0.2);
            width: fit-content;
          }

          .status.inactive {
            color: #DB524E;
            background: rgba(247, 0, 0, 0.2);
            width: fit-content;
          }
        }
      }
    }
  }

  .triangle {
    position: absolute;
    top: 43px;
    left: 93%;
  }

  .triangle::after {
    content: "";
    position: absolute;
    z-index: 10000;
    display: flex;
    justify-content: flex-end;
    //left: 50% !important;
    margin-left: -8px;
    border-width: 19px;
    border-style: solid;
    border-color: #fff;
    border-left: 10px solid transparent;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  .customer-detail {
    width: 100%;

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      color: #003B5B;

      svg {
        margin-right: 6px;
        cursor: pointer;
      }
    }
  }

  .no-subscription {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-top: 2rem;
  }
}

@media(max-width:1160px) {
  .subscribed-customers .table-wrapper table td {
    font-size: 11px;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;