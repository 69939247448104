.membership-detail {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  height: 100vh;
  width: 100%;
   position: relative;
  .scrollable {
    max-height: 62vh;
    overflow-y: auto;

    img {
      height: 300px;
    }
  }

  .packagestatus {
    justify-content: flex-end;
  }

  .packageName {
    display: flex;
    justify-content: space-between;
    padding: 0 2% 0 0;
  }

  .breadcrumbs {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: #003B5B;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;

    .icon-leftoutlined {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    width: 100%;

    .heading {
      font-weight: 600;
      color: #003B5B;
      margin-right: 2rem;
    }

    .status {
      font-weight: 500;
      font-size: 16px;
      color: #DB524E;
      background: rgba(247, 0, 0, 0.2);
      border-radius: 5px;
      padding: 5px 15px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        height: 7px;
        width: 7px;
        background: #DB524E;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .active {
      color: #27AE60;
      background: rgba(39, 174, 96, 0.2);

      span {
        background: #27AE60;
      }
    }
  }

  .description {
    font-weight: 600;
    font-size: 20px;
    color: #003B5B;
  }

  .price {
    font-weight: 500;
    color: #E2AE2C;
  }

  .detail {
    font-weight: 400;
    font-size: 16px;
    color: #111111;
  }

  .points {
    font-weight: 400;
    font-size: 14px;
    color: #6B6B6B;

    span {
      display: inline-block;
      height: 7px;
      width: 7px;
      background: #6B6B6B;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .termsandcondition-heading {
    font-weight: 600;
    font-size: 20px;
    color: #003B5B;
  }

  .termsandcondition {
    font-weight: 400;
    font-size: 14px;
    color: #6B6B6B;
    opacity: 0.6;
  }

  button {
    width: 50%;
    max-width: 500px;
    height: 60px;
    margin-bottom: 0.5rem;
    background: #003B5B;
    box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    text-align: center;
    border: transparent;
    position: absolute;
    bottom: 0.5rem;

    .icon {
      margin: 0 0 7px 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .membership-detail {
    padding: 14px;

    .heading {
      font-size: 18px;
    }

    .price {
      font-size: 16px;
    }

    .detail {
      font-size: 12px;
    }

    button {
      width: 90%;
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .membership-detail {
    padding: 16px;

    .heading {
      font-size: 22px;
    }

    .price {
      font-size: 18px;
    }

    .detail {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .membership-detail {
    padding: 20px;

    .heading {
      font-size: 24px;
    }


    .price {
      font-size: 22px;
    }

    .detail {
      font-size: 14px;
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;