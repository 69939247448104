.dashboardpage {
  display: flex;
}

.nonewsfeed {
  img {
    width: 200px;
    height: 200px;
  }
}

.newsfeed {
  overflow-y: scroll;
  height: 90vh;
  padding: 1rem 0;
  gap: 1rem;
  display: grid;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.newsfeedcol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  background: #fff;
  border-radius: 15px;
  row-gap: 10px;
  max-width: 620px;
  min-width: 300px;
  text-align: justify;
  box-shadow: 0px 12px 26px -4px #003B5B14;
  position: relative;
  height: 425px;

  .newsediticon {
    width: 35px;
  }

  .newsimg {
    width: 100%;
    height: 250px;
    position: relative;

    .ant-carousel {
      height: 100%;

      .slick-slider {
        height: 100%;
      }
    }

    .slick-list {
      max-width: 575px;
      min-width: 300px;
      height: 100%;

      .slick-track {
        height: 100%;

        // width:100% !important;
        .slick-slide {
          height: 100%;

          //  width:100% !important;
          // left:0px !important;
          // position:absolute !important;
          // top: -160px !important;
          div {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }

            video {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .newfeedcontent {
    margin-top: 15px;
    word-wrap: break-word;
    width:100%;

    .newsfeedtitle {
      color: #003B5B;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px
    }

    // .newsfeedtitle {
    //   margin-top: 70px;
    // }
  }

  .carouselimage {
    max-width: 620px;
    min-width: 300px;
    aspect-ratio: 2/0.98;
    border-radius: 10px;
    object-fit: cover;
  }

  .newsfeedtitle {
    padding: 10px;
    color: #912665;
    background-color: #fff;
    position: absolute;
    top: 3%;
    left: 2%;
    border-radius: 5px;
    min-width: 70px;
    max-width: 200px;
    text-align: center;
    font-weight: 600;
    z-index: 1;
    font-size: 18px;
  }

  .newsediticon {
    position: absolute;
    right: 2.1rem;
    top: 1.5rem;
    z-index: 1;
    cursor: pointer;
    background: white;
    border-radius: 50%;
    padding: 8px;
  }
}

.newsplusicon {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 2vh;
  right: 2vh;
}

.newsfeedmodal {
  .newfeedcolmodal {
    width: 600px;
    height: 450px;
    padding: 10px;
    box-shadow: 0px 12px 26px -4px #003B5B14;
  }

  .ant-modal-content {
    height: 630px;
  }

  .newfeedheader {
    color: #003B5B;
    font-size: 30px;
    font-weight: 600;
    height: 9vh;
  }

  .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .newsfeedheading {
    margin-top: 2%;
    margin-bottom: 2%;
    color: #111111;
    font-size: 20px;
  }

  .continue_btn_disabled {
    width: 94%;
    margin-bottom: 3%;
    background-color: #003B5B !important;
    opacity: 0.2;
    color: #fff !important;
    margin-left: 3%;
  }

  .continue_btn {
    width: 94%;
    margin-bottom: 3%;
    background-color: #003B5B !important;
    color: #fff !important;
    margin-left: 3%;
  }

  .textareainput {
    height: 150px !important;
  }

  .imagebox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2%;
    width: 540px;
    height: 250px;
    border: 2px solid #005586;
    border-style: dotted;
    border-radius: 5px;
  }

  .editiconimg {
    position: relative;
    left: 90%;
  }
}

.crossiconimg {
  position: absolute;
  right: -17px;
  top: -27px;
  width: 27px;
  cursor: pointer;
}

.newsfeedmodalshow {
  .newsfeedcolmodal {
    position: relative;
    margin: auto;
    top: 13px;

  }

  .ant-modal-content {
    height: 600px;
    width: 550px;
  }

}


.newsimageimg {
  width: 500px;
  height: 200px;
  object-fit: contain;
  margin-bottom: 3%
}

.newsimageimg1 {
  width: 500px;
  height: 274px;
  object-fit: cover;
  margin-bottom: 3%;
  border-radius: 8px;
}

.addcheckbox .ant-checkbox .ant-checkbox-inner:after {
  height: 13.142857px;
  width: 5.714286px;
}

.nonewsfeed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  height: 80vh;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #003B5B;
  border-color: #003B5B;
}

@media(min-width:1600px) {
  .newsfeedcol .newfeedcontent {
    margin-top: 0px;
  }

  // .newsfeedcol .newsimg {
  //   height: auto;
  // }

  // .newsfeedcol {
  //   width: 390px !important;
  //   height:464px;
  // }

  // .newsfeed {
  //   gap: 2%;
  // }

  // .newsfeedcol .carouselimage {
  //   width: 375px !important;
  //   height: 275px !important;
  // }
}

@media(min-width:1920px) {
  .newsfeedcol {
    .newsfeedtitle {
      font-size: 12px;
      width: 96px;
    }

    // .newsimg {
    //   height: auto;
    // }
  }
}

@media (max-width: 850px) {
  // .newsfeedcol .newsimg {
  //   height: auto !important;
  // }

  .newsfeed .newsfeedtitle {
    width: 95px;
    font-size: 15px;
  }
}

@media(min-height:900px) {
  .newsfeedmodal .newfeedheader {
    height: 5vh;
  }
}

@media screen and (max-width: 500px) {
  .newsfeed {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 501px) and (max-width: 820px) {
  .newsfeed {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 821px) and (max-width: 1400px) {
  .newsfeed {
    grid-template-columns: repeat(2, 1fr);

    .carouselimage {
      max-width: 550px;
      min-width: 300px;
    }

    .newsfeedcol .newfeedcontent {
      margin-top: 15px;
    }
  }
}

@media screen and (min-width: 830px) and (max-width: 999px) {
  // .newsfeedcol .newsimg {
  //   height: auto;
  // }

  .newsfeed .newsfeedtitle {
    width: 94px;
    font-size: 10px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .newsfeed {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1600px) {
  .newsfeed {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 2000px) {
  .newsfeed {
    grid-template-columns: repeat(4, 1fr);
  }
}

.deletemodal {
  .crossImage {
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      margin: auto
    }
  }

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    color: #111111;
    font-weight: 600;
    font-size: 20px;
    width: 270px;
    margin: auto;
    text-align-last: center;
    margin-bottom: 10px;
  }

  .confirmButtons {
    justify-content: space-between;
    display: flex;
    height: 50px;
    margin-top: 20px;

    // margin-bottom: 30px;
    .yesbtn {
      width: 39%;
      height: 100%;
      background-color: #E7E8EA;
      padding: 8px 12px;
      border-radius: 15px;
      outline: none;
      border-color: #E7E8EA;
      font-size: 16px;
      font-weight: 400
    }

    .nobtn {
      width: 56%;
      height: 100%;
      margin-left: 5px;
      background-color: #003B5B;
      color: #fff;
      padding: 8px 15px;
      border-radius: 15px;
      font-size: 16px;
      font-weight: 450;
    }
  }

  .ant-modal-content {
    top: 30%;
    padding: 24px 24px;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;