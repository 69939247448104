.otp-input-container {
    justify-content: center;

    input.otp-input {
        background: #fff !important;
        border-radius: 0.75rem !important;
        font-size: 1rem !important;
        border: 1px solid #D1D5DB;
        height: 70px !important;
        width: 70px !important;
        margin-left: 5% !important;

        &:focus-visible {
            outline: none;
        }
    }

}
.resendbtn{
    span{
        margin-top:0.6%;
        margin-right:20px; 
    }
}
#otp-resend-root{
    span{
        margin-top:2px;
        margin-left:20px;
    }
}

.otp-input-container>input.otp-input:last-child {
    margin-right: 0px !important;
}

.resend-otp-btn {
    color: blue !important;

    &:disabled {
        color: #ccc !important;
    }

    &:hover {
        background-color: #fff !important;
    }
}

@media(max-width:500px) {
    .otp-input-container {
        input.otp-input {
            width: 45px !important;
        }
    }


}

@media(max-width:400px) {
    .otp-input-container {
        input.otp-input {
            width: 38px !important;
        }
    }


}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;